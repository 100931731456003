import {
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LogoWhite from "../resources/logo.png";
import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SendIcon from "@mui/icons-material/Send";
import Mail from "../resources/icons/mail.png";
import Phone from "../resources/icons/phone.png";
import Location from "../resources/icons/location.png";
function Footer() {
  const goToTopOfThePage = () => {
    window.scrollTo(0, 0);
  };
  const navigateToPrivacyAr = () => {
    window.open("/privacy-ar", "_blank");
  };
  return (
    <div className="footer-container w-100 p-3">
      <div className="d-flex flex-row justify-content-between mb-5">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <IconButton
            className=" text-white"
            aria-label="arrow-up"
            onClick={goToTopOfThePage}
            size="large"
          >
            <ArrowUpwardIcon />
          </IconButton>
          <p className="m-0">إنتقل للأعلى</p>
        </div>
        <Button
          variant="text"
          color="secondary"
          className="fw-bold"
          onClick={() => navigateToPrivacyAr()}
        >
          سياسة الخصوصية لدايت فيت
        </Button>
        <img src={LogoWhite} alt="logo-white" className="logo-footer" />
      </div>
      <div className=" footer-bottom-container ">
        <div dir="ltr" className="d-flex align-items-center">
          © 2024 dietfit. All rights reserved.
        </div>

        <div className="footer-info-all-container">
          <div className="footer-info-container">
            <p className="m-0" style={{ fontSize: "12px", fontWeight: "600" }}>
              Tripoli , Address for cmp
            </p>
            <img src={Location} alt="mail" className="icon-footer" />
          </div>
          <div className="footer-info-container">
            <p className="m-0" style={{ fontSize: "12px", fontWeight: "600" }}>
              +218 92 000 0000{" "}
            </p>
            <img src={Phone} alt="mail" className="icon-footer" />
          </div>
          <div className="footer-info-container">
            <p className="m-0" style={{ fontSize: "12px", fontWeight: "600" }}>
              <a
                href="mailto:Dietfit_support@novatech.ly"
                target="_blank"
                rel="emailsend to"
                className="mail-tag"
              >
                Dietfit_support@novatech.ly
              </a>
            </p>
            <img src={Mail} alt="mail" className="icon-footer" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

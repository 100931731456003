import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, post } from "../service/apiService";
import { Meal } from "../models/MealModel";
import burn from "../resources/icons/burn.png";
import { Button, Chip, IconButton, List, ListItem } from "@mui/material";
import SimpleDialog from "../components/DialogBox";
import { ArrowForward } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import CardMealInfoStats from "../components/cards/CardMealInfoStats";

function MealInfo() {
  const { mealId } = useParams();
  const [mealData, setMealData] = useState<Meal>({} as Meal);
  const [openDialog, setOpenDialog] = useState(false);
  const [dishes, setDishes] = useState<any>([]);
  const location = useLocation();
  const [error, setError] = useState<string>("");
  const currentMealTime = location.pathname.split("/")[1];
  const [mealTime, setMealTime] = useState<string>(
    currentMealTime === "breakfastmeals"
      ? "breakfast"
      : currentMealTime === "lunchmeals"
      ? "lunch"
      : currentMealTime === "dinnermeals"
      ? "dinner"
      : "Snacks "
  );
  useEffect(() => {
    const element: HTMLElement | null = document.getElementById(
      "element-meal-container"
    );
    get(`/Meal/${mealId}?openTime=${mealTime}`)
      .then((res: any) => {
        setMealData(res);
        setDishes(res?.dishes[0]);
      })
      .catch((e) => {
        setError("حدث خطأ ما");
      });
  }, [mealId]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const element: HTMLElement | null = document.getElementById(
      "element-meal-container"
    );
    if (element) {
      element.style.backgroundImage = `url(${mealData.imageSrc})`;
      element.style.backgroundSize = "cover";
      element.style.backgroundRepeat = "no-repeat";
      element.style.backgroundPosition = "center";
    }
  }, [dishes]);

  const addMeal = () => {
    post("/Profile/meal/open", { mealId: mealId, openTime: mealTime })
      .then((res) => {
        setMealData(res);
        setDishes(res?.dishes[0]);
      })
      .catch((e) => {
        setError(e.response.data || "حدث خطأ ما");
      });
  };

  return (
    <div className="main-container1 ">
      <section className="meal-details-section ">
        <div
          id="element-meal-container"
          className="d-flex flex-row align-items-end food-details-img w-100  rounded"
        >
          <div className="img-title-food-details">
            <div className="d-flex flex-row align-items-center">
              <IconButton
                aria-label="back"
                onClick={() => window.history.back()}
                color="primary"
                className="p-0  mt-4 icon-with-background "
              >
                <ArrowForward />
              </IconButton>

              <p className="header-meal-info-calories-container text-end px-3 pb-0 w-100 mt-4 bold">
                {mealData.mealName}
              </p>
            </div>
            <div className="meal-info-calories-container">
              <img src={burn} alt="burn" className="img-calory-burn m-2" />
              <h1 className="m-0 mx-2">{mealData.totalCal}</h1>
              <span className="fw-bold disc-text-tag p-1 mx-2  text-white">
                سعرة حرارية
              </span>
            </div>
            {/* <h3 className=" m-0 p-0">سعره حرارية</h3> */}
          </div>
        </div>
        <div className="bottom-section ">
          <div className="d-flex justify-content-center align-items-end flex-column  w-100 ">
            <div className="meal-info-details-table-container">
              {mealData?.dishes ? (
                <div className="meal-info-details-table-right px-2">
                  <span className="meal-details-header-color back-ground-shadow  fw-bold fs-3">
                    المكونات :
                  </span>

                  {mealData?.dishes[0]?.ingredients.map(
                    (item: any, index: number) => {
                      return (
                        <p className="m-0 pt-3">
                          {item.ingredientName}
                          {index !==
                            mealData?.dishes[0]?.ingredients.length - 1}
                        </p>
                      );
                    }
                  )}
                </div>
              ) : (
                "لا يوجد مكونات"
              )}
              <div className="meal-info-details-table-left px-2">
                <span className="meal-details-header-color fw-bold fs-3">
                  طريقة التحضير :
                </span>

                {mealData?.dishes &&
                mealData?.dishes[0]?.instructions.length > 0 ? (
                  <>
                    <ul>
                      {dishes.instructions?.map((step: any, index: number) => (
                        <li className="mt-3">
                          <p className="m-0 me-2">{step.instruction}</p>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <>
                    <div>
                      لرؤية طريقة التحضير الرجاء الإضافة الي الفطور القادم
                    </div>
                    {/* <Button
                      variant="contained"
                      color="success"
                      className="mt-3"
                      onClick={() => addMeal()}
                    >
                      إضافة الى فطوري القادم
                    </Button> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-flex justify-content-center w-50">
        <div className="mt-5">
          {mealData?.dishes && (
            <CardMealInfoStats
              mealId={mealId}
              setMealData={setMealData}
              setDishes={setDishes}
              isOpened={mealData?.dishes[0]?.instructions.length > 0}
              mealTime={mealTime}
              mealFat={mealData.totalFat}
              mealCarb={mealData.totalCarb}
              mealProtein={mealData.totalProtein}
              calories={mealData.totalCal}
              setError={setError}
              openDialog={setOpenDialog}
              hideAddButton={mealData.hideAddButton}
            />
          )}
        </div>
      </section>

      <SimpleDialog data={error} open={openDialog} onClose={handleClose} />
    </div>
  );
}

{
  /* <div className="d-flex flex-row justify-content-between align-items-center mb-2 w-50 mt-5">
            <img src={burn} alt="burn" className="img-calory-burn" />
            <h1 className="m-0">{mealData.totalCal}</h1>
            <span className="fw-bold disc-text-tag p-1">سعرة حرارية</span>
          </div>
          <div className="d-flex justify-content-between w-50">
            <Chip
              label={`دهون: ${mealData.totalFat}`}
              className="m-1 fw-bolder text-black"
              color="success"
            />
            <Chip
              label={`كاربوهيدرات: ${mealData.totalCarb}`}
              className="m-1 text-black"
              color="warning"
            />
            <Chip
              label={`بروتين: ${mealData.totalProtein}`}
              className="m-1 text-black"
              color="primary"
            />
          </div> */
}
{
  /* <Button
            variant="contained"
            color="secondary"
            className="mt-3"
            onClick={() => setOpenDialog(true)}
          >
            طريقة التحضير
          </Button> */
}

export default MealInfo;

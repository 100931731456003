import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { Chip, DialogActions, IconButton } from "@mui/material";

export interface SimpleDialogProps {
  data: string;
  open: boolean;
  onClose: () => void;
  accept: () => void;
}

function TermsAndConditionsDialog(props: SimpleDialogProps) {
  const { onClose, open, data, accept } = props;
  const content = <div dangerouslySetInnerHTML={{ __html: data }} />;
  const handleClose = () => {
    accept();
  };
  const handleRejectionClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogActions dir="ltr">
        <IconButton onClick={handleClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <div className="p-3">{content}</div>
    </Dialog>
  );
}

export default TermsAndConditionsDialog;

import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { styled, alpha } from "@mui/material/styles";
import Logo from "../resources/logoDark.png";
import Profile from "../resources/icons/profile.png";
import Scale from "../resources/icons/scaleblue.png";
import Unwanted from "../resources/icons/undesiredComponents.png";
import Clock from "../resources/icons/clockBlue.png";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Button,
  Container,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Home, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../resources/icons/profileNavbar.png";
import ScaleNav from "../resources/icons/scaleNavbar.png";
import HomeIcon from "@mui/icons-material/Home";
import SubscriptionDialog from "./Dialog/SubscriptionDialog";

const settings = [
  { img: "home", name: "الرئيسية", path: "/" },
  { img: Profile, name: "البيانات الشخصية", path: "/info" },
  { img: Scale, name: "القيم الغذائية", path: "/nutrition" },
  { img: Unwanted, name: "المكونات الغير مرغوب بها", path: "/undesired" },
  { img: Clock, name: "حالة الإشتراك", path: "subscription" },
  { img: Clock, name: "تسجيل الخروج", path: "reload" },
];

const pages = ["Products", "Pricing", "Blog"];

function NavBar() {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const user = localStorage.getItem("userProfileDto");
  const userObj = user && JSON.parse(user);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleHome = () => {
    navigate("/");
  };
  const handleSettings = () => {
    navigate("/settings");
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleNavigate = (path: string) => {
    if (path === "reload") {
      localStorage.clear();
      window.location.reload();
    } else if (path === "/") {
      navigate("/");
    } else if (path === "subscription") {
      setOpenDialog(true);
    } else navigate(`/settings${path}`);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <AppBar
      position="static"
      color="primary"
      sx={{ backgroundColor: "#13322B33" }}
      dir="ltr"
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <img
              src={Logo}
              className="logo-container"
              onClick={() => handleHome()}
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <div className="nav-container rounded  p-2 my-2 mx-2">
                <img src={ProfileNav} className="icon-nav m-0" />
                <div className="d-flex flex-column mx-2">
                  <p className="m-0 header-nav-container">مرحبا بك </p>
                  <p className="m-0 body-nav-container fw-bold">
                    {userObj.firstName}
                  </p>
                </div>
              </div>
              <div className="nav-container rounded p-2 mx-2">
                <img src={ScaleNav} className="icon-nav m-0" />
                <div className="d-flex flex-column mx-2">
                  <p className="m-0 header-nav-container">وزنك الحالي</p>
                  <p className="m-0 body-nav-container fw-bold">
                    {userObj.currentWeight} كجم
                  </p>
                </div>
              </div>
            </Menu>
          </Box>

          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            dir="rtl"
          >
            <div className="nav-container rounded me-5 p-2 my-2">
              <img src={ProfileNav} className="icon-nav m-0" />
              <div className="d-flex flex-column mx-2">
                <p className="m-0 header-nav-container">مرحبا بك </p>
                <p className="m-0 body-nav-container fw-bold">
                  {userObj.firstName}
                </p>
              </div>
            </div>
            <div className="nav-container rounded p-2 me-5">
              <img src={ScaleNav} className="icon-nav m-0" />
              <div className="d-flex flex-column mx-2">
                <p className="m-0 header-nav-container">وزنك الحالي</p>
                <p className="m-0 body-nav-container fw-bold">
                  {userObj.currentWeight} كجم
                </p>
              </div>
            </div>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.name}
                  onClick={() => handleNavigate(setting.path)}
                >
                  {setting.img === "home" ? (
                    <HomeIcon />
                  ) : (
                    <img src={setting.img} className="menu-icon" />
                  )}
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <SubscriptionDialog open={openDialog} onClose={handleClose} />
    </AppBar>
  );
}

export default NavBar;

import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

function ConfirmSnackBar(props: any) {
  const [open, setOpen] = useState(props.open);
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        dir="ltr"
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          تم اعتماد تغييراتك
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ConfirmSnackBar;
